import StudyHeader from '../../../components/molecules/studyHeader';
import Summary from '../../../components/molecules/summary';
import GoTop from '../../../components/atoms/goTop';
import ProjectCarousel from '../../../components/molecules/projectCarousel';
import Text, {TextElement} from '../../../components/atoms/text';
import styles from '../projects.module.scss';

import classNames 	from 'classnames';

const Robert = () => {
  const summarySteps = [
    "Organisation des tests utilisateurs : définition des objectifs des tests, de la cible etc.",
    "Mise en place d’un screener pour recruter les personnes cibles.",
    "Rédaction du protocole de tests en fonction des objectifs de test.",
    "Passation des tests utilisateurs en binôme.",
    "Analyse et restitution."
  ]

  const summaryTools = [
    "Word",
    "Typeform",
    "Teams",
    "Airtable",
    "PowerPoint"
  ]

	const paragraphTabAndSpace = classNames({
		[styles.paragraphSpace]: true,
		[styles.tab]: true,
	});

	const paragraphTabAndSpace2 = classNames({
		[styles.paragraphSpace2]: true,
		[styles.tab]: true,
	});

  return (
    <div>
      <GoTop />
      <StudyHeader
        title="Nathan - Bordas - Le Robert"
        text="Test du nouvel affichage des ressources numériques"
        tag="User research"
        url="../img/robert.png"
      />

      <Summary
        className={styles.paragraphSummary}
        steps={summarySteps}
        tools={summaryTools}
        role="User researcher - alternance"
        duration="Sept.-oct. 2022"
      />

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Contexte.</Text>
        <Text as={TextElement.Body}>Les Éditions Nathan, Bordas et Le Robert proposent aux enseignants des ressources numériques en ligne. Ces ressources numériques viennent compléter les manuels des enseignants. Lesdites ressources peuvent être de différents types : des images, des vidéos, des corrigés etc.</Text>
        <Text as={TextElement.Body}>Pour des questions budgétaires, l’équipe en charge des Éditions Nathan, Bordas et Le Robert a du <span className={styles.bold}>modifier la façon d’accéder aux ressources numériques</span> - qui se faisait au clic sur la ressource - <span className={styles.bold}>mais également l’affichage de celles-ci.</span> </Text>
        <Text className={styles.paragraphSpace} as={TextElement.Body}>La Product Owner du projet nous a demandé, à mon collaborateur et moi-même, de réaliser des tests utilisateurs sur des maquettes qu’elle avait elle-même élaborées en proposant une nouvelle alternative pour l’accès et l’affichage des ressources numériques.</Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Méthode de recrutement.</Text>
        <Text as={TextElement.Body}>Afin de recruter le panel souhaité, <span className={styles.bold}>nous avons mis en place un screener</span> qui a été intégré à une newsletter dédiée aux enseignants et enseignantes des Éditions Nathan, Bordas et Le Robert. </Text>
        <Text className={styles.paragraphSpace} as={TextElement.Body}><span className={styles.bold}>Au clic sur le bouton “Je participe” </span>(cf. l’image de la strate de la newsletter ci-dessous), <span className={styles.bold}>la personne était redirigée vers un questionnaire Typeform</span> qui permettait, grâce aux questions posées, de faire le tri entre les profils qui nous intéressaient et ceux qui ne nous intéressaient pas.</Text>
        <img className={styles.imageScreener} alt="Strate dédiée au recrutement dans la newsletter" src='/img/robert-screener.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Strate dédiée au recrutement dans la newsletter</span></Text>
        <Text as={TextElement.Header3}>Screener</Text>
        <Text as={TextElement.Body}>Pour le screener, nous avons utilisé <span className={styles.bold}>l’outil Typeform.</span> En fonction des réponses, soit les enseignants et enseignantes avaient un profil qui ne correspondait pas à notre cible et dans ce cas-là ces personnes étaient redirigées vers la fin du questionnaire, soit le profil correspondait et dans ce cas-là les personnes étaient redirigées vers un lien Calendly pour choisir un créneau pour le test utilisateur. </Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Méthodes de recherche choisies.</Text>
        <Text as={TextElement.Body}>Nous avons choisi 3 méthodes de recherche : 1. les tests utilisateurs 2. des entretiens post-tests et 3. une échelle d’utilisabilité.</Text>

        <Text className={paragraphTabAndSpace} as={TextElement.Header3} >1. Test utilisateur</Text>
        <Text as={TextElement.Body}>La méthode qui nous a semblé la plus adéquate était effectivement celle des tests utilisateurs. <span className={styles.bold}>Cela nous a permis d’évaluer l’alternative proposée par la Product Owner pour accéder aux ressources numériques ainsi que l’affichage desdites ressources.</span></Text>
        <Text as={TextElement.Body}>L’évaluation de cette interface passait par l’observation des actions réalisées par les utilisateurs et utilisatrices.</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Cela nous a permis d’évaluer l’alternative proposée par la Product Owner pour accéder aux ressources numériques ainsi que l’affichage desdites ressources.</span></Text>
        <Text className={styles.paragraphSpace} as={TextElement.Header4}>Format des tests utilisateurs</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Tous les tests ont été réalisés à distance, de façon synchrone.</span> Cela nous a permis :</Text>
        <ul className={paragraphTabAndSpace}>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>d’avoir une multiplicité de configurations testées : en effet, chaque personne utilise des tailles d’écrans différentes qui ont permis de tester la maquette sur différentes résolutions</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>de <span className={styles.bold}>faire faire le test aux utilisateurs et utilisatrices directement depuis chez eux et chez elles </span>: ainsi, les utilisateurs et utilisatrices sont mis et mises en confiance</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>d’échanger avec des personnes qui étaient géographiquement distantes </span>de nos locaux</Text></li>
        </ul>

        <div className={styles.cardExample}>
          <Text as={TextElement.Header5}>Exemples de scénarios des tests utilisateurs</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Avant de commencer le cours, vous désirez que vos élèves s’entraînent à la synthèse. Comment faites-vous pour leur montrer la fiche d’auto-évaluation ?</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Vous demandez à vos élèves de travailler sur le tableau/l’oeuvre « Leila » de l’artiste Dicksee qui se trouve dans « Fascination for exotic lands ». Comment l’affichez-vous ?</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Le cours arrive à sa fin et vous demandez à votre classe de faire l’évaluation. Comment faites-vous pour afficher le sujet ?</Text>
        </div>

        <Text className={paragraphTabAndSpace2} as={TextElement.Header3}>2. Entretien post-tests</Text>
        <Text as={TextElement.Body}>À l’issue des tests utilisateurs, nous avons mené <span className={styles.bold}>des entretiens semi-directifs afin de recueillir les impressions et les ressentis </span>des utilisateurs et utilisatrices sur la maquette testée.</Text>
        <div className={styles.cardExample}>
          <Text as={TextElement.Header5}>Exemples de questions post-test</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Qu’avez-vous pensé de ce nouvel affichage des ressources sur les côtés de la double page ?</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Comment vous êtes-vous senti(e) lors de l’utilisation de cette interface ?</Text>
        </div>

        <Text className={paragraphTabAndSpace2} as={TextElement.Header3}>3. Échelle d’utilisabilité</Text>
        <Text as={TextElement.Body}>Pour clore les entretiens, nous avons demandé aux utilisateurs et utilisatrices d’<span className={styles.bold}>évaluer, grâce au questionnaire SUS, l’utilisabilité du système</span>, toujours dans le but de recueillir l’opinion de la cible. Néanmoins, tout comme les entretiens utilisateurs, les échelles d’utilisabilité peuvent être soumises à des biais de passation (effet de halo, tendance à l’acquiescement). Ainsi, il est primordial de faire attention à la tournure des questions posées, à l’écrit comme à l’oral.</Text>
        <Text className={styles.textSpace} as={TextElement.Body}>Malheureusement, <span className={styles.bold}>je me suis rendue compte tardivement que ce genre d’échelle est particulièrement utile pour comparer plusieurs versions d’un même système</span>, dans le cas d’un cycle de conception itératif et/ou tester un système auprès de plusieurs catégories d’utilisateurs afin de différencier leur opinion, ce qui n’était pas notre cas dans cette mission.</Text>

        <Text className={styles.paragraphSpace3} as={TextElement.Header3}>Plan de test utilisateurs</Text>

        <div className={styles.testUserPlan}>
          <div className={styles.testProduct}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Produit testé</Text>
            <Text as={TextElement.BodyCard}>Biblio Manuels enseignants qui permet à ces derniers de projeter et visualiser les manuels numériques ainsi que leurs ressources numériques.</Text>
          </div>

          <div className={styles.testGoal}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Objectifs des tests utilisateurs</Text>
            <ol className={styles.tab2}>
              <li className={styles.itemList}><Text as={TextElement.BodyCard}>1. Les enseignants font-ils le lien entre une ressource présente dans la double page et les zones contextuelles ?</Text></li>
              <li className={styles.itemList}><Text as={TextElement.BodyCard}>2. Les ressources sont-elles toujours identifiables sur les maquettes ?</Text></li>
              <li className={styles.itemList}><Text as={TextElement.BodyCard}>3. La pénibilité de la tâche : aller-retour entre la double page et les zones contextuelles</Text></li>
            </ol>
          </div>

          <div className={styles.users}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Participants et participantes</Text>
            <Text as={TextElement.BodyCard}>Tous et toutes enseignants et enseignantes, principalement de l’élémentaire (cible principale), qui utilisent au quotidien les ressources des manuels numériques.</Text>
          </div>

          <div className={styles.tasks}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Tâches et scénarios du test</Text>
            <Text as={TextElement.BodyCard}>Tâches demandées : afficher les ressources numériques liées à une double page d’un manuel numérique puis ouvrir certaines d’entre elles.</Text>
            <Text className={styles.textSpace} as={TextElement.BodyCard}>Quelques exemples de scénarios :</Text>
            <ul>
              <li className={styles.itemListCard}><span className={styles.dot2}></span><Text as={TextElement.BodyCard}>“C’est mardi matin, vous avez cours avec votre classe de 3ème et vous continuez votre cours sur le chapitre « Freaky Dreams ». Vous souhaitez afficher à vos élèves la fiche d’auto-évaluation. Comment faites-vous ?”</Text></li>
              <li className={styles.itemListCard}><span className={styles.dot2}></span><Text as={TextElement.BodyCard}>“Vous étudiez le paragraphe sur Greystock avec votre classe et vous souhaitez leur montrer la vidéo associée. Comment vous y prenez-vous ?”</Text></li>
            </ul>
          </div>

          <div className={styles.equipment}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Équipement</Text>
            <Text as={TextElement.BodyCard}>Pour les participants et participantes : un ordinateur avec une connexion internet stable, un micro et une webcam.</Text>
            <Text className={styles.textSpace} as={TextElement.BodyCard}>Pour les researchers : même équipement + une grille d’observation et un stylo.</Text>
          </div>

          <div className={styles.responsability}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Responsabilités</Text>
            <Text as={TextElement.BodyCard}>50% des tests : observation et prise de notes.</Text>
            <Text as={TextElement.BodyCard}>50% des tests : animation des tests utilisateurs.</Text>
          </div>

          <div className={styles.datetime}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Lieu et date</Text>
            <Text as={TextElement.BodyCard}>Passion des tests du 6 au 14 octobre 2022, à distance, via l’outil Teams.</Text>
          </div>

          <div className={styles.steps}>
            <Text className={styles.titleCard} as={TextElement.TitleStudy1}>Étapes des tests utilisateurs</Text>
            <Text as={TextElement.BodyCard}>Explication du déroulé > Réaliastion des scénarios > Entretien post-tests > Échelle d'utilisabilité</Text>
          </div>
        </div>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Sur le modèle du livre Méthodes de design UX de Carine Lallemand</span></Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Analyse et restitution.</Text>
        <Text as={TextElement.Body}>Une fois la passation des tests utilisateurs terminée, j’ai <span className={styles.bold}>retranscrit sur l’outil Airtable</span> les retours et problèmes rencontrés sur la plateforme actuelle, les retours et les problèmes rencontrés sur les maquettes, les verbatims ainsi que les tentatives réalisées par les participants et participantes pour réaliser chaque tâche.</Text>
        <img className={styles.imageScreener} alt="Extrait de la retranscription sur Airtable" src='/img/robert-airtable.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Extrait de la retranscription sur Airtable</span></Text>

        <Text as={TextElement.Body}>De cette façon, cela nous a permis de lister chaque retour et les trier selon leur catégorie : issue, feature request, constat ou positive feedback.</Text>
        <Text className={styles.paragraphSpace} as={TextElement.Body}><span className={styles.bold}>Concernant les verbatims : après les avoir tous notés, nous les avons triés afin de les regrouper par catégorie.</span></Text>
        <Text className={styles.paragraphSpace} as={TextElement.Body}>Une fois ces informations regroupées, j’ai pu rédiger le dossier de restitution dans lequel j’ai indiqué les tâches qui ont été réalisées dès la 1ère tentative et les tâches qui ont demandé plus de tentatives.</Text>

        <Text className={styles.paragraphSpace} as={TextElement.Header3} >Constats et besoin issus des tests</Text>
        <Text as={TextElement.Header4}>Constats</Text>
        <ul>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>Après quelques tentatives les utilisateurs finissent par se repérer, identifier les ressources et atteindre rapidement l'objectif demandé...</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>... Néanmoins, <span className={styles.bold}>les participants et participantes trouvent que ce mode d’affichage n’est ni pratique ni intuitif</span></Text></li>
        </ul>
        <Text className={styles.paragraphSpace} as={TextElement.Header4}>Besoin</Text>
        <ul className={styles.paragraphSpace}>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>Un besoin de rapidité pour trouver les ressources</span> : les enseignants et enseignantes sont face à 30 élèves qui peuvent vite être déconcentrés</Text></li>
        </ul>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Autres cas d’études.</Text>
        <ProjectCarousel reject={"/project/robert"}/>
      </section>
    </div>
  )
};

export default Robert;
