import { Outlet, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import classNames   from 'classnames';

import Menu   from '../../molecules/menu';
import styles from './mainLayout.module.scss';


const MainLayout = ({ className, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div
      {...props}
      className={classNames({
        [styles.wrapper]: true,
        ...(className ? {[className]: true} : {}),
      })}
    >
      <Menu
        className={styles.menu}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      <main className={
        classNames({
          [styles.content]: !pathname.match("project"),
          [styles.projectContent]: pathname.match("project"),
          [styles.hideContent]: isMenuOpen
        })}>
        <Outlet />
      </main>
      <footer className={styles.footer}>
        <a href="https://www.linkedin.com/in/ayla-bianchi/" target="_blank">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="#000" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1101_129)">
              <path d="M29 0.750116H3C2.41008 0.744112 1.84187 0.972377 1.42005 1.38482C0.998227 1.79727 0.757253 2.36021 0.75 2.95012V29.0551C0.758564 29.6442 1.00012 30.2058 1.42179 30.6172C1.84347 31.0286 2.41093 31.2561 3 31.2501H29C29.59 31.2548 30.1578 31.0258 30.5794 30.6131C31.001 30.2004 31.2421 29.6375 31.25 29.0476V2.94262C31.2395 2.35444 30.9972 1.79418 30.5759 1.38363C30.1546 0.973077 29.5883 0.74541 29 0.750116Z" fill="#353535"/>
              <path d="M5.26479 12.1826H9.79228V26.7501H5.26479V12.1826ZM7.52979 4.93262C8.04907 4.93262 8.55668 5.08663 8.98842 5.37518C9.42015 5.66373 9.7566 6.07385 9.95521 6.55365C10.1538 7.03345 10.2056 7.56138 10.1042 8.07065C10.0027 8.57992 9.75242 9.04764 9.38505 9.41466C9.01769 9.78167 8.54973 10.0315 8.04036 10.1325C7.531 10.2335 7.00312 10.1811 6.52351 9.98208C6.0439 9.78302 5.6341 9.44618 5.34596 9.01417C5.05782 8.58216 4.90429 8.0744 4.90479 7.55512C4.90545 6.85936 5.1823 6.19232 5.67451 5.70058C6.16672 5.20884 6.83403 4.93262 7.52979 4.93262ZM12.6323 12.1826H16.9723V14.1826H17.0323C17.6373 13.0376 19.1123 11.8301 21.3148 11.8301C25.8998 11.8201 26.7498 14.8376 26.7498 18.7501V26.7501H22.2223V19.6626C22.2223 17.9751 22.1923 15.8026 19.8698 15.8026C17.5473 15.8026 17.1523 17.6426 17.1523 19.5526V26.7501H12.6323V12.1826Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_1101_129">
                <rect width="32" height="32" fill="#000"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </footer>
    </div>
  );
};

export default MainLayout;
