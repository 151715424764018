import classNames from 'classnames';
import Text, {TextElement} from '../../atoms/text';
import Highlight from '../../atoms/highlight';
import styles from './studyheader.module.scss';

const StudyHeader = ({title, text, tag, url, className}) => {
  const StudyHeaderClass = classNames({
    [styles.wrapper]: true,
    ...(className ? {[className]: true} : {}),
  });

  const words = text.split(" ")
  const firstWord = words.reverse().pop()
  const otherWords = words.reverse().join(" ")

  return (
    <div className={StudyHeaderClass}>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Text as={TextElement.Legend}> {title} </Text>
          <span className={styles.cardDot}></span>
          <Text as={TextElement.Legend}> #{tag} </Text>
        </div>
        <Text as={TextElement.Header1}> <Highlight> {firstWord} </Highlight> {otherWords} </Text>
      </div>
      <img className={styles.image} alt="" src={url}/>
    </div>
  )
};

export default StudyHeader;
