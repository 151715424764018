import styles from './contact.module.scss';
import Text, {TextElement} from '../../components/atoms/text';

const Contact = () => {

  return (
    <div className={styles.container}>
      <div className={styles.line}></div><a href="mailto:ayla.bianchi@protonmail.com" ><Text as={TextElement.Header3} className={styles.name}> ayla.bianchi@protonmail.com</Text></a>
    </div>
  )
};

export default Contact;
