import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Text, {TextElement} from '../../atoms/text';

import styles from './cardProject.module.scss';

const CardProject = ({subtitle, title, tag, imgUrl, url, notReady, key, className}) => {
  const wrapper = classNames({
    [styles.cardWrapper]: true,
    [styles.isNotReady]: notReady,
    ...(className ? {[className]: true} : {})
  })

  const image = classNames({
    [styles.cardImg]: !notReady,
    [styles.cardImgNotReady]: notReady
  })


  return (
    notReady ?
      (
        <div className={wrapper} to={url} key={key}>
          <div className={image} id="cardImg">
            <img alt={subtitle} src={imgUrl}/>
          </div>

          <Text className={styles.cardOther} as={TextElement.ClientName}>{subtitle}</Text>
          <Text className={styles.cardOther} as={TextElement.TitleStudy1}>{title}</Text>
          <Text className={styles.cardOther} as={TextElement.Legend}>{tag}</Text>
        </div>
      ) : (
        <Link className={wrapper} to={url} key={key}>
          <div className={image} id="cardImg">
            <img alt={subtitle} src={imgUrl}/>
          </div>

          <Text className={styles.cardOther} as={TextElement.ClientName}>{subtitle}</Text>
          <Text className={styles.cardOther} as={TextElement.TitleStudy1}>{title}</Text>
          <Text className={styles.cardOther} as={TextElement.Legend}>{tag}</Text>
        </Link>
      )

    

  )
};

export default CardProject ;
