import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';

const Button = forwardRef(
  ({ children, className, ...props }, ref) => {
    const buttonClass = classNames({
      [styles.wrapper]: true,
      ...(className ? { [className]: true } : {}),
    });

    return (
      <div {...props} className={buttonClass} ref={ref}>
        <button >
          {children}
        </button>
      </div>
    );
  }
);

export default Button;
