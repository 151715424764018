import { useCallback } from 'react';
import { NavLink }     from 'react-router-dom';
import { HashLink }    from 'react-router-hash-link';
import classNames      from 'classnames';

import Text, {TextElement} from '../../atoms/text';
import styles from './menu.module.scss';

const Menu = ({className, isMenuOpen, setIsMenuOpen}) => {

  const closeMenu = useCallback(() => {setIsMenuOpen(false)}, [setIsMenuOpen])

  return  (
    <menu className={
      classNames({
        [styles.wrapper]: true,
        ...(className ? {[className]: true} : {}),
      })}>
      <div className={styles.svg}>
        <NavLink to="/" onClick={() => closeMenu()}>
          <img
            alt="Ayla Bianchi"
            src="/svg/name.svg"
          />
        </NavLink>
      </div>

      <div className={styles.links}>
        <HashLink smooth to="/#studies" onClick={() => closeMenu()} >
          <Text className={styles.link} as={TextElement.Body}>
            Cas d'études
          </Text>
        </HashLink>

        <NavLink
          className={({ isActive }) =>
            classNames({
              [styles.link]: true,
              [styles.active]: isActive
            })}
          to="/about"
          onClick={() => closeMenu()} >
          <Text as={TextElement.Body}>
            À propos
          </Text>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            classNames({
              [styles.link]: true,
              [styles.active]: isActive
            })}
          to="/contact"
          onClick={() => closeMenu()} >
          <Text as={TextElement.Body} >
            Contact
          </Text>
        </NavLink>
      </div>
    </menu>
  )
};

export default Menu;
