import StudyHeader from '../../../components/molecules/studyHeader';
import Summary from '../../../components/molecules/summary';
import GoTop from '../../../components/atoms/goTop';
import Text, {TextElement} from '../../../components/atoms/text';
import ProjectCarousel from '../../../components/molecules/projectCarousel';
import styles from '../projects.module.scss';

import classNames 	from 'classnames';

const Retz = () => {
  const summarySteps = [
    "Organisation des tests utilisateurs : définition des objectifs des tests, de la cible etc.",
    "Mise en place d’un screener pour recruter les personnes cibles.",
    "Rédaction du protocole de tests en fonction des objectifs de test.",
    "Passation des tests utilisateurs individuellement.",
    "Analyse et restitution."
  ]

  const summaryTools = [
    "Word",
    "Hotjar",
    "Typeform",
    "Teams",
    "Airtable",
    "PowerPoint",
  ]

  const paragraphTabAndSpace = classNames({
    [styles.paragraphSpace]: true,
    [styles.tab]: true,
  });

  return (
    <div>
      <GoTop />
      <StudyHeader
        title="Éditions Retz"
        text="Test de la nouvelle interface et de l’arboresence"
        tag="User research"
        url="../img/retz.png"
      />

      <Summary
        className={styles.paragraphSummary}
        steps={summarySteps}
        tools={summaryTools}
        role="User researcher - alternance"
        duration="Janv.-Fév. 2023"
      />

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Contexte.</Text>
        <Text as={TextElement.Body}>Les Éditions Retz sont spécialisées dans les domaines de l'innovation pédagogique. <span className={styles.bold}>Les équipes ont souhaité refondre le site web</span>. Des wireframes et des maquettes ont été réalisés. <span className={styles.bold}>Les équipes ont profité de cette refonte pour revoir l’architecture de l’information.</span></Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Afin de valider les maquettes et la nouvelle arborescence, j’ai décidé de réaliser des tests utilisateurs.</span> Ces derniers ont été réalisés sur maquettes, c’est-à-dire avant développement.</Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Méthode de recrutement.</Text>
        <Text as={TextElement.Body}>Avant de commencer la phase de recrutement, j’ai eu à disposition deux documents qui m’ont permis de comprendre qui visite le plus ce site : enseignants et enseignantes élémentaires, c’est-à-dire du CP au CM2. À partir de ce document, j’ai pu commencer à réfléchir au recrutement du panel.</Text>
        <Text as={TextElement.Body}>J’ai alors décidé de <span className={styles.bold}>créer une sonde Hotjar</span> qui était visible sur quasiment toutes les pages du site web et pour tous et toutes les visiteurs et visiteuses. Cette sonde contenait uniquement une question : « Bonjour, auriez-vous 1 minute à nous accorder pour nous aider à améliorer votre expérience sur le site Retz ? ». Si l’utilisateur répondait par la négative alors il pouvait continuer sa navigation sur le site Retz. Si l’utilisateur répondait par la positive alors il était redirigé vers un questionnaire Typeform dans le but de filtrer les profils.</Text>
        <img className={styles.imageScreener} alt="Capture d’écran de la sonde Hotjar" src='/img/retz-hotjar.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Capture d’écran de la sonde Hotjar</span></Text>
        <Text as={TextElement.Header3}>Screener</Text>
        <Text as={TextElement.Body}>Pour avoir le panel que je cherchais, <span className={styles.bold}>j’ai mis en place un screener grâce à l’outil Typeform.</span> Dans ce screener, 6 questions étaient posées. Certaines sur le profil : enseignant, parents d’élèves etc. D’autres sur le niveau enseigné : maternelle, élémentaire etc. Ou d’autres encore sur l’ancienneté. Les personnes qui avaient le profil cible, c’est-à-dire enseignants ou enseignantes de l’élémentaire avec entre 2 et 30 ans d’ancienneté étaient redirigées vers un Calendly pour choisir un créneau pour le test utilisateur.</Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Méthode de recherche choisie.</Text>
        <Text as={TextElement.Header3}>Test utilisateur</Text>
        <Text as={TextElement.Body}>Afin d’évaluer la nouvelle interface et la nouvelle arborescence, j’ai décidé d’organiser des tests utilisateurs. En effet, parmi les méthodes existantes, <span className={styles.bold}>c’était la méthode la plus adaptée pour répondre à mes 2 objectifs</span> : les utilisateurs arrivent-ils à s’orienter avec le nouveau menu ? Et les utilisateurs arrivent-ils à se repérer dans la fiche produit et à y accomplir leurs objectifs ?</Text>

        <Text className={styles.paragraphSpace} as={TextElement.Header4}>Format des tests utilisateurs</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Tous les tests ont été réalisés à distance, de façon synchrone.</span> Cela nous a permis :</Text>
        <ul className={paragraphTabAndSpace}>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>d’avoir une multiplicité de configurations testées : en effet, chaque personne utilise des tailles d’écrans différentes qui ont permis de tester la maquette sur différentes résolutions</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}>de <span className={styles.bold}>faire faire le test aux utilisateurs et utilisatrices directement depuis chez eux et chez elles </span>: ainsi, les utilisateurs et utilisatrices sont mis et mises en confiance</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>d’échanger avec des personnes qui étaient géographiquement distantes </span>de nos locaux</Text></li>
        </ul>

        <div className={styles.cardExample}>
          <Text as={TextElement.Header5}>Exemples de scénarios demandés de réaliser lors des tests utilisateurs</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Vous avez entendu parler du manuel scolaire « Lecture Piano CP » et vous souhaitez accéder à sa fiche produit, comment faites-vous ?</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Vous vous demandez si le manuel numérique enseignant existe pour cette méthode, comment faites-vous pour le vérifier ?</Text>
          <Text className={styles.textSpace} as={TextElement.Body}>Ce manuel vous plaît bien mais avant de le commander vous souhaitez vérifier s’il existe un site compagnon pour cet ouvrage. Comment vous y prenez-vous ?</Text>
        </div>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Passation.</Text>
        <Text className={styles.textSpace} as={TextElement.Body}>Pour ces tests utilisateurs <span className={styles.bold}>j’étais seule pour les animer et pour prendre des notes.</span> Je me suis davantage concentrée sur l’animation en notant sur ma grille d’observation les “time codes” importants pour la retranscription.</Text>
        <Text className={styles.textSpace} as={TextElement.Body}><span className={styles.bold}>Ça a été un exercice plutôt compliqué </span>car il fallait à la fois animer, regarder et écouter ce que la personne faisait et disait, rebondir sur ce qu’elle faisait et/ou disait puis noter. J’ai privilégié l’animation à la prise de notes. Les échanges étant enregistrés, j’ai pu revenir dessus par la suite.</Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Analyse et restitution.</Text>
        <Text className={styles.textSpace} as={TextElement.Body}><span className={styles.bold}>C’est sur Airtable que j’ai retranscrit les tests utilisateurs.</span> J’y ai ajouté les verbatims mais aussi les étapes de réalisation de chaque tâche par chaque participant. De cette manière <span className={styles.bold}>j’ai pu visuellement avoir une idée des tâches qui ont demandé aux utilisateurs plusieurs tentatives</span>.Pour ces tests utilisateurs j’étais seule pour les animer et pour prendre des notes. Je me suis davantage concentrée sur l’animation en notant sur ma grille d’observation les “time codes” importants pour la retranscription.</Text>
        <img className={styles.imageScreener} alt="Capture d’écran d’Airtable pour les tests utilisateurs des Éditions Retz" src='/img/retz-airtable.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Capture d’écran d’Airtable pour les tests utilisateurs des Éditions Retz</span></Text>
        <Text className={styles.textSpace} as={TextElement.Body}>Une fois les verbatims et les scénarios retranscrits, j’ai pu commencer la restitution dans laquelle j’ai indiqué, en plus des objectifs des tests utilisateurs, du panel des testeurs et testeuses, le déroulement des tests utilisateurs (toutes les personnes présentes lors de la restitution ne connaissaient pas cette méthode), j’ai parlé des <span className={styles.bold}>constats faits après l’animation et l’observation des tests utilisateurs.</span></Text>
        <Text className={styles.paragraphSpace} as={TextElement.Header3} >Constats issus des tests</Text>
        <ul>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>La nouvelle arborescence a été comprise de la plupart</span> des utilisateurs et utilisatrices. Cette nouvelle arborescence a été qualifiée de “pratique”, “facile” ou encore “intuitive”. Cependant, <span className={styles.bold}>certaines personnes ont révélé une inquiétude quand aux ouvrages de cycle</span>, c’est-à-dire des ouvrages pour plusieurs classes (CM1/CM2 par exemple) : comment seront-ils accessibles ? Via le lien CM1 ? Via le lien CM2 ? Via les 2 ?</Text></li>
          <li className={styles.itemList}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>Quant à la page produit, les utilisateurs et utilisatrices n’ont pas rencontré de difficultés particulières.</span> Quand certains et certaines ont utilisé les onglets présents dans la fiche produit pour atteindre l’objectif demandé, d’autres ont préféré scroller dans la page. Lorsque j’ai demandé à ces personnes pourquoi elles n’étaient pas passé par les onglets dédiés, toutes m’ont répondu qu’elles ont préféré scroller dans la page “par curiosité, pour voir les informations présentes.”</Text></li>
        </ul>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Autres cas d’études.</Text>
        <ProjectCarousel reject={"/project/retz"}/>
      </section>
    </div>
  )
};

export default Retz;
