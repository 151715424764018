import classNames from 'classnames';
import Carousel from "react-multi-carousel";

import styles from './projectCarousel.module.scss';
import "react-multi-carousel/lib/styles.css";

import CardProject from '../cardProject';
import { Research, UX } from '../../../constants';

const ProjectCarousel = ({className, reject}) => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };

  const projects = Research.concat(UX).filter(({url, notReady}) => {
    return url !== reject && !notReady;
  })

  return  (
    <div className={
      classNames({
        [styles.wrapper]: true,
        ...(className ? {[className]: true} : {}),
      })}>
      <Carousel responsive={responsive} partialVisible={true} itemClass={styles.imageItem} >
        {projects.map(({subtitle, title, tag, imgUrl, url, notReady}, index) =>
          (<CardProject
             subtitle={subtitle}
             title={title}
             tag={tag}
             imgUrl={imgUrl}
             url={url}
             notReady={notReady}
             index={index}
             className={styles.noWidth}
           />))}
      </Carousel>
    </div>
  )
};

export default ProjectCarousel ;
