import classNames from 'classnames';
import Text, {TextElement} from '../../atoms/text';
import styles from './summary.module.scss';

const Summary = ({steps, tools, role, duration, className}) => {
  const SummaryClass = classNames({
    [styles.wrapper]: true,
    ...(className ? {[className]: true} : {}),
  });

  const format = (number) => {
    return ((number < 10) ? '0' + number.toString() : number.toString()) + '.';
  };

  const Step = (index, step) => {
    return (
      <div className={styles.step} key={index}>
        <Text className={styles.stepNumber} as={TextElement.Number}>
          {format(index+1)}
        </Text>
        <Text className={styles.stepValue} as={TextElement.Body}>
          {step}
        </Text>
      </div>
    )
  }

  return (
    <div className={SummaryClass}>
      <div className={styles.steps}>
        {steps.map((step, index) => Step(index, step))}
      </div>

      <div className={styles.others}>
        <div className={styles.group}>
          <Text className={styles.title} as={TextElement.Body}>Rôle</Text>
          <Text className={styles.value} as={TextElement.Body}>{role}</Text>
        </div>
        <div className={styles.group}>
          <Text className={styles.title} as={TextElement.Body}>Durée</Text>
          <Text className={styles.value} as={TextElement.Body}>{duration}</Text>
        </div>
        <div className={styles.group}>
          <Text className={styles.title} as={TextElement.Body}>Outils</Text>
          <ul>
            {tools.map((tool, index) => <li key={index}><Text className={styles.value} as={TextElement.Body}>+ {tool}</Text></li>)}
          </ul>
        </div>
      </div>
    </div>
  )
};

export default Summary;
