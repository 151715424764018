import styles from './about.module.scss';
import Text, {TextElement} from '../../components/atoms/text';
import Button from '../../components/atoms/button';

const About = () => {

  return (
    <div>
      <div className={styles.container}>
        <section className={styles.text}>
          <Text as={TextElement.Body}>Confucius a dit : <span className={styles.italic}>“Si l’homme a deux oreilles et une bouche, c’est pour écouter deux fois plus qu’il ne parle”.</span> Si cette citation résonne profondément en moi, c’est parce qu’elle fait écho à mon métier d’user researcher/UX designer que j’occupe depuis 2 ans suite à ma reconversion professionnelle.</Text>
          <Text className={styles.space} as={TextElement.Body}>Si cette reconversion a été une réussite, c’est notamment grâce à ma <span className={styles.bold}>persévérance</span>. Dans tout ce que j'entreprends, je cherche toujours à repousser mes limites et à <span className={styles.bold}>sortir de ma zone de confort</span>.</Text>
          <Text as={TextElement.Body}>Et pour moi, sortir de ma zone de confort c’est, entre autres, acquérir de nouvelles connaissances pour essayer, <span className={styles.bold}>échouer au besoin pour comprendre,</span> puis <span className={styles.bold}>recommencer</span>.</Text>
          <Text className={styles.space} as={TextElement.Body}><span className={styles.bold}>Et si ma vive énergie me permet d’aller au bout des choses</span>, c’est que mon obstination pour le travail bien fait y est pour quelque chose. :)</Text>
        </section>
        <img className={styles.imageScreener} alt="Moi" src='/img/moi.png'/>
      </div>

      <a href="./img/CV.pdf" target="_blank"> <Button className={styles.space2}>Voir mon CV</Button></a>
    </div>
  )
};

export default About;
