import classNames from 'classnames';
import styles from './highlight.module.scss';

const Highlight = ({children}) => {
  const highlightClass = classNames({
    [styles.wrapper]: true,
  });

  return (
    <span className={highlightClass}>{children}</span>
  );
};

export default Highlight;
