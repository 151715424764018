import StudyHeader from '../../../components/molecules/studyHeader';
import Summary from '../../../components/molecules/summary';
import GoTop from '../../../components/atoms/goTop';
import ProjectCarousel from '../../../components/molecules/projectCarousel';
import Text, {TextElement} from '../../../components/atoms/text';
import styles from '../projects.module.scss';

import classNames 	from 'classnames';

const Capeezy = () => {
  const summarySteps = [
    "Visionnage d’enregistrements de session Hotjar (sur le tunnel d’achat).",
    "Audit ergonomique sur le tunnel d’achat (critères de Bastien et Scapin).",
  ]

  const summaryTools = [
    "Hotjar",
    "Excel",
  ]

  const paragraphTabAndSpace = classNames({
    [styles.paragraphSpace]: true,
    [styles.tab]: true,
  });

  return (
    <div>
      <GoTop />
      <StudyHeader
        title="Capeezy"
        text="Analyse du tunnel d’achat Capeezy"
        tag="User research"
        url="../img/capeezy.png"
      />

      <Summary
        className={styles.paragraphSummary}
        steps={summarySteps}
        tools={summaryTools}
        role="User researcher - alternance"
        duration="Mars 2023"
      />

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Contexte.</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Capeezy est la plateforme de soutien scolaire en ligne des éditions Bordas</span> pour que les élèves puissent travailler en toute autonomie les matières principales au primaire, au collège et au lycée. <span className={styles.bold}>3 offres sont disponibles</span> à ce jour : </Text>
        <ol className={styles.tab}>
          <li><Text as={TextElement.Body}>1. La formule ciblée pour un enfant seulement et une matière au choix entre les mathématiques, le français ou l’anglais</Text></li>
          <li><Text as={TextElement.Body}>2. La formule réussite pour un enfant seulement mais avec toutes les matières</Text></li>
          <li><Text as={TextElement.Body}>3. La formule tribu jusqu’à 4 enfants et toutes les matières</Text></li>
        </ol>
        <Text className={styles.paragraphSpace} as={TextElement.Body}>Selon les données Analytics données par le service marketing de Capeezy, <span className={styles.bold}>16% des personnes vont jusqu’à la page de paiement mais seule 1 personne sur 10 va au bout du tunnel.</span></Text>
        <Text as={TextElement.Body}>Une refonte du tunnel d’achat est prévue mais le taux de conversion étant faible, l’équipe de Capeezy nous a mandaté pour réaliser un audit sur le tunnel d’achat actuel afin de lister ce qui doit être amélioré/changé pour le futur tunnel d’achat.</Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Méthodes de recherche choisies.</Text>
        <Text as={TextElement.Body}>Après avoir eu un point avec l’équipe Capeezy pour comprendre leur besoin, j’ai décidé de 1) <span className={styles.bold}>réaliser un audit ergonomique</span> en me basant sur <span className={styles.bold}>les critères de Bastien et Scapin</span> et de 2) <span className={styles.bold}>visionner des enregistrements de session</span> grâce à l’outil Hotjar <span className={styles.bold}>pour comprendre où les utilisateurs et utilisatrices rencontraient des difficultés</span> ou encore sur quelle(s) page(s) ils et elles quittaient le site. Les enregistrements de session m’ont aussi permis de confirmer ce que j’avais déjà relevé grâce à l’audit ergonomique réalisé en amont.</Text>
        <Text className={styles.paragraphSpace} as={TextElement.Header3}>1. Audit ergonomique</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>J’ai décidé de réaliser un audit ergonomique</span> dans le but d’évaluer le tunnel d’achat du site Capeezy. J’ai choisi de réaliser cette analyse car elle permet d'<span className={styles.bold}>identifier les problèmes à résoudre afin d'optimiser l'expérience utilisateur et d'augmenter les conversions.</span></Text>

        <Text as={TextElement.Body}>Pour cet audit, je me suis basée sur les critères de Bastien et Scapin.</Text>
        <Text as={TextElement.Body}>Pour chaque critère non respecté, j’ai indiqué <span className={styles.bold}>la priorité du problème</span> :</Text>

        <ul className={paragraphTabAndSpace}>
          <li className={styles.itemList2}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>Faible</span> : ne freine en rien la navigation de l’utilisateur ou de l’utilisatrice. Il s'agit d'incohérences graphiques par ex.</Text></li>
          <li className={styles.itemList2}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>Élevée</span> : ralentit les utilisateurs ou utilisatrices au cours de l'exécution d'une tâche. (frustration)</Text></li>
          <li className={styles.itemList2}><span className={styles.dot}></span><Text as={TextElement.Body}><span className={styles.bold}>Bloquante</span> : pose problème à l'utilisateur ou l’utilisatrice pour réaliser sa tâche</Text></li>
        </ul>
        <Text className={styles.paragraphSpace} as={TextElement.Body}><span className={styles.bold}>Pour chaque problème relevé, j’ai proposé des recommandations.</span> Malheureusement toutes n’étaient techniquement pas possibles ou très chronophages.</Text>
        <img className={styles.imageScreener} alt="Extrait de l’audit ergonomique pour le site Capeezy" src='/img/capeezy-audit.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Extrait de l’audit ergonomique pour le site Capeezy</span></Text>
        <Text as={TextElement.Body}>Suite à ce travail, <span className={styles.bold}>j’ai réalisé un document qui regroupait toutes les incohérences</span> présentes au sein du site Capeezy et <span className={styles.bold}>qui pouvaient être ajustées rapidement dans le but d’améliorer l’expérience utilisateur</span> :</Text>
        <img className={styles.imageScreener} alt="Fichier regroupant les incohérences sur le site Capeezy" src='/img/capeezy-inconsistency.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Fichier regroupant les incohérences sur le site Capeezy</span></Text>
        <Text as={TextElement.Header3}>2. Visionnage de sessions via Hotjar</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>J'ai préféré dissocier le visionnage des enregistrements mobiles et desktop.</span> En effet, les utilisateurs et utilisatrices des deux environnements pouvaient faire face à des problèmes différents.</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>J’ai visionné uniquement les sessions de tunnel d’achat</span> c’est-à-dire à partir du moment où une personne cliquait sur une des 3 offres jusqu’au moment du paiement si cette personne allait jusqu’au paiement.</Text>
        <Text as={TextElement.Body}>Ci-dessous un extrait du fichier Excel que j’ai réalisé et dans lequel j’ai indiqué pour chaque enregistrement visionné les étapes du tunnel d’achat et les actions réalisées par les utilisateurs et utilisatrices. </Text>
        <img className={styles.imageScreener} alt="Extrait du fichier Excel qui regroupe une partie des sessions visionnées" src='/img/capeezy-excel.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Extrait du fichier Excel qui regroupe une partie des sessions visionnées</span></Text>

        <Text as={TextElement.Body}><span className={styles.bold}>Grâce à ce second travail j’ai pu faire un constat sur desktop et sur mobile.</span> Cela m’a également permis de valider les informations que le service marketing m’avaient transmises au sujet du tunnel d’achat. Autrement dit, que peu de personnes vont jusqu’au bout du tunnel.</Text>
        <Text as={TextElement.Body}>Une fois ces informations résumées, j’ai pu les comparer avec ce que j’avais relevé grâce à l’audit ergonomique et les présenter à l’équipe Capeezy.</Text>
        <Text as={TextElement.Body}>Une réunion a eu lieu pour que je présente à la fois l’audit et ce qui ressortait des visionnages Hotjar.</Text>
        <Text as={TextElement.Body}><span className={styles.bold}>Le but de cette mission était de mettre rapidement en place des corrections pour améliorer l’expérience utilisateur en attendant la refonte du site. Après réflexion, il aurait été intéressant de mettre en place des sondes de satisfaction avant et après les corrections pour mesurer la satisfaction des utilisateurs quant aux modifications apportées.</span></Text>
      </section>


      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Autres cas d’études.</Text>
        <ProjectCarousel reject={"/project/capeezy"}/>
      </section>
    </div>
  )
};

export default Capeezy;
