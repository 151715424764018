import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './text.module.scss';

const TextElement = {
    Header1: 'header1',
    Header2: 'header2',
    Header3: 'header3',
    Header4: 'header4',
    Header5: 'header5',
    BlocText: 'blocText',
    Body: 'body',
    BodyBold: 'bodyBold',
    BodyLink: 'bodyLink',
    BodyCard: 'bodyCard',
    Quote: 'quote',
    ClientName: 'clientName',
    TitleStudy1: 'titleStudy1',
    TitleStudy2: 'titleStudy2',
    ProjectTitle: 'projectTitle',
    Legend: 'legend',
    Number: 'number',
    Icon: 'icon',
    Svg: 'svg',
};


const Element = {
    header1: 'h1',
    header2: 'h2',
    header3: 'h3',
    header4: 'h4',
    header5: 'h5',
    blocText: 'p',
    body: 'p',
    bodyBold: 'p',
    bodyLink: 'p',
    bodyCard: 'p',
    quote: 'q',
    clientName: 'h6',
    titleStudy1: 'h6',
    titleStudy2: 'h6',
    projectTitle: 'h6',
    legend: 'legend',
    number: 'p',
    icon: 'div',
};

const Text = forwardRef((
    {
        as,
        isBold = false,
        className,
        children,
        ...props
    }, ref) => {
        const Component = Element[as];
        const textClass = classNames({
            [styles[as]]: true,
            [styles.bold]: isBold,
            ...(className ? {[className]: true} : {}),
        });

        return (
            <Component
              ref={ref}
              className={textClass}
              {...props}>
              {children}
            </Component>
        );
    });


export default Text;
export {
    TextElement,
};
