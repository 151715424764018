import { Routes, Route } from 'react-router-dom';

import MainLayout from '../components/layouts/main';
import MainPage   from './mainPage';
import Robert     from './projects/robert';
import Retz       from './projects/retz';
import Capeezy    from './projects/capeezy';
import ValDoise   from './projects/val-doise';
import About      from './about';
import Contact    from './contact';
import NotFound   from './notFound';

import '../styles/main.scss';

const App = () => {
  return (
    <Routes>
      <Route element={<MainLayout />} >
        <Route path='/' element={<MainPage/>} />
        <Route path='/project/robert' element={<Robert />} />
        <Route path='/project/retz' element={<Retz />} />
        <Route path='/project/capeezy' element={<Capeezy />} />
        <Route path='/project/val-doise' element={<ValDoise />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
