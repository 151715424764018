const Research = [
  {
    subtitle: 'Nathan - Bordas - Le robert',
    title: 'Les enseignants arrivent-ils à accéder aux ressources numériques ?',
    tag: '#User research',
    imgUrl: '/img/robert.png',
    url: '/project/robert',
    notReady: false
  },
  {
    subtitle: 'Editions Retz',
    title: 'Évaluation de la nouvelle interface ainsi que sa nouvelle arborescence',
    tag: '#User research',
    imgUrl: '/img/retz.png',
    url: '/project/retz',
    notReady: false
  },
  {
    subtitle: 'Capeezy',
    title: 'Analyse du tunnel d\'achat afin de diminuer le taux de rebond',
    tag: '#User research',
    imgUrl: '/img/capeezy.png',
    url: '/project/capeezy',
    notReady: false,
  },
  {
    subtitle: 'Éditions Retz v2',
    title: '[En cours de rédaction] Évaluation globale du nouveau site ainsi que de son tunnel d’achat',
    tag: '#User research',
    imgUrl: '/img/retzv2.png',
    url: '/project/retzv2',
    notReady: true,
  },
];

const UX = [
  {
    subtitle: 'Département du Val d’Oise',
    title: 'Refonte du site web du département',
    tag: '#UX design',
    imgUrl: '/img/valDoise.png',
    url: '/project/val-doise',
    notReady: false,
  }
];

export { Research, UX }
