import { useRef } from 'react';
import styles from './main.module.scss';

import Text, {TextElement} from '../../components/atoms/text';
import CardProject from '../../components/molecules/cardProject';
import Button from '../../components/atoms/button';
import Highlight from '../../components/atoms/highlight';
import { Research, UX } from '../../constants';


const MainPage = () => {

  const projects = useRef(null)
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.intro}>
        <div className={styles.introTextBlock}>
          <Text as={TextElement.Header1}>
            UX researcher.
          </Text>
          <Text className={styles.reverseText} as={TextElement.Header1}>
            UX designer.
          </Text>
          <Text className={styles.presentation} as={TextElement.Body}>
            Ma mission consiste à étudier les comportements, les attentes et les usages de vos utilisateurs afin d’optimiser leur expérience sur votre produit.
          </Text>
          <Button className={styles.studyButton} onClick={() => scrollToSection(projects)}>
            Découvrez mes cas d'études
          </Button>
        </div>

        <img className={styles.introImageBlock} alt="Ayla Bianchi" src="./img/profile.png"/>
      </div>

      <Text className={styles.block} as={TextElement.BlocText}>
        Mon objectif est donc de <Highlight>résoudre</Highlight> des <Highlight>problématiques d’utilisabilité</Highlight> de manière à proposer un produit <Highlight>fonctionnel</Highlight> et <Highlight>intuitif</Highlight>.
      </Text>

      <Text id="studies" ref={projects} as={TextElement.Header1}>
        Cas d'études.
      </Text>

      <Text className={styles.txt} as={TextElement.ProjectTitle}>#User research</Text>

      <div className={styles.research}>
        {Research.map(({subtitle, title, tag, imgUrl, url, notReady}, index) => (
          <CardProject
            subtitle={subtitle}
            title={title}
            tag={tag}
            imgUrl={imgUrl}
            url={url}
            notReady={notReady}
            index={index}
          />
        ))}
      </div>

      <Text className={styles.txt} as={TextElement.ProjectTitle}>#UX design</Text>

      {UX.map(({subtitle, title, tag, imgUrl, url, notReady}, index) => (
        <CardProject
          subtitle={subtitle}
          title={title}
          tag={tag}
          imgUrl={imgUrl}
          url={url}
          notReady={notReady}
          index={index}
        />
      ))}

      <Text className={styles.block2} as={TextElement.BlocText}>
        Les <Highlight>méthodes de recherche</Highlight> sont choisies en fonction du <Highlight>projet</Highlight> et de ses <Highlight>objectifs</Highlight>.
      </Text>
    </div>
  );
};


export default MainPage;
