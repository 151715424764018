import { forwardRef, useCallback } from 'react';
import classNames 	from 'classnames';

import IconsMap, { IconType } from './iconsMap';
import styles from './icon.module.scss';

const IconElement = {
	Span: 'span',
	Button: 'button'
}

const IconColor = {
	Primary: 'primary',
	Secondary: 'secondary',

};

const IconSize = {
	Small: 'small',
	Medium: 'medium',
	Large: 'large',
};

const IconStyle =  {
	Outline: 'outline',
	Solid: 'solid',
};

const Icon = forwardRef((
	{
		variant,
		as = IconElement.Span,
		color,
		iconStyle = IconStyle.Solid,
		size = IconSize.Medium,
		className,
		...props
	},
	ref) => {
		const Component = as;
		const iconTemplate = IconsMap[variant];
		const getHTML = useCallback(() => ({__html: iconTemplate}), [iconTemplate]);
		const iconClass = classNames({
			[styles.wrapper]: true,
			[styles[size]]: true,
			...(color ? {[styles[color]]: true} : {}),
			...(color && iconStyle ? {[styles[`${iconStyle}-${color}`]]: true} : {}),
			...(className ? {[className]: true} : {}),
		});

		return (
			iconTemplate
				?
				<Component
				  dangerouslySetInnerHTML={getHTML()}
				  ref={ref}
				  className={iconClass}
				  {...props}>
				</Component>

			: null
		);
	});


export default Icon;
export {
	IconType,
	IconsMap,
	IconElement,
	IconColor,
	IconStyle,
	IconSize,
};
