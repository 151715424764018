import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Icon, { IconColor, IconSize, IconType } from '../../atoms/icon';

import styles from './goTop.module.scss';

const GoTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = window.innerWidth < 575
  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if ( window.pageYOffset < 2000 ) {
        setIsVisible(false)
        return
      }
      setIsVisible(true)

    })
  }, [setIsVisible]);

  return (
    <div
      className={
        classNames({
          [styles.wrapper]: true,
          [styles.isHidden]: !isVisible || isMobile
        })}
      onClick={() => scroll()}
    >
      <Icon
        className={styles.tool}
        variant={IconType.ArrowUp}
        color={IconColor.Secondary}
        size={IconSize.Medium}
      />
    </div>
  )
};

export default GoTop;
