import StudyHeader from '../../../components/molecules/studyHeader';
import Summary from '../../../components/molecules/summary';
import ProjectCarousel from '../../../components/molecules/projectCarousel';
import GoTop from '../../../components/atoms/goTop';
import Text, {TextElement} from '../../../components/atoms/text';
import styles from '../projects.module.scss';

const ValDoise = () => {
  const summarySteps = [
    "Atelier tri de cartes pour challenger l’arborescence actuelle du site.",
    "Conception de wireframes en fonction du besoin client.",
    "Tests utilisateurs pour tester la nouvelle interface et la nouvelle arborescence du site.",
  ]

  const summaryTools = [
    "Miro",
    "Figma",
    "Word"
  ]

  return (
    <div>
      <GoTop />
      <StudyHeader
        title="Département du Val d’Oise"
        text="Refonte du site du Val d’Oise"
        tag="UX design"
        url="../img/valDoise.png"
      />

      <Summary
        className={styles.paragraphSummary}
        steps={summarySteps}
        tools={summaryTools}
        role="UX designer - alternance"
        duration="Mars-Mai 2022"
      />

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Contexte.</Text>
        <Text as={TextElement.Body}>Depuis la dernière refonte du site du Val d’Oise, les usages du web ont  été largement modifiés. Les visiteurs sont en attente de réponses plus rapides, personnalisées et facilement identifiables. Le produit doit s’adapter à leurs attentes et non plus l’inverse. <span className={styles.bold}>Le département du Val d’Oise nous a fait appel pour refondre leur site web.</span></Text>
        <Text as={TextElement.Body}>L’équipe du Département du Val d’Oise m’a également demandé de <span className={styles.bold}>challenger l’arborescence du site.</span></Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Atelier tri de cartes.</Text>
        <Text as={TextElement.Body}>Avec l’aide d’un collègue, j’ai organisé, à distance, sur Miro, <span className={styles.bold}>un atelier tri de cartes fermé</span>. Par manque de temps, le client n’a pas souhaité recruter des habitants et habitantes du site du Val d’Oise. Les participantes étaient des agentes du Département. </Text>
        <Text as={TextElement.Body}>Nous avons décidé de faire un atelier “<span className={styles.bold}>Groupe concurrent in-person</span>” c’est-à-dire de créer 2 groupes de 3 personnes pour que les participants travaillent en équipe et arrivent plus rapidement à un consensus.</Text>
        <img className={styles.imageScreener} alt="Captures d’écran de l’atelier tri de cartes" src='/img/valDoise-cards.png'/>
        <Text className={styles.legendScreener} as={TextElement.Legend}><span className={styles.bold}>Captures d’écran de l’atelier tri de cartes</span></Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Wireframes.</Text>
        <Text as={TextElement.Body}>J’ai ensuite pu commencer les wireframes. Bien que plus de la moitié des utilisateurs et utilisatrices utilisent leur mobile pour accéder au site web du département (données Analytics), <span className={styles.bold}>le client souhaitait que je réalise dans un premier temps les wireframes desktop.</span></Text>
        <Text className={styles.linkContainer} as={TextElement.Body}>Si vous souhaitez voir les wireframes, <a href="../img/wireframes-val-d-oise.pdf" target="_blank"><span className={styles.link}> cliquez ici.</span> </a> </Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Tests utilisateurs.</Text>
        <Text as={TextElement.Body}>Après avoir réalisé les wireframes <span className={styles.bold}>nous avons mené 6 tests utilisateurs</span>. Comme pour l’atelier du tri par cartes, les participantes étaient des agentes du Conseil Départemental du Val d’Oise. Chaque test a duré environ trente minutes. Nous avons passé tous les tests en binôme : un animateur + un scribe. Nous avons demandé de réaliser des tâches sur l’interface. <span className={styles.bold}>Ces tests nous ont principalement permis d’évaluer la nouvelle navigation.</span></Text>
      </section>

      <section className={styles.paragraph}>
        <Text as={TextElement.Header2}>Autres cas d’études.</Text>
        <ProjectCarousel reject={"/project/val-doise"}/>
      </section>
    </div>
  )
};

export default ValDoise;
