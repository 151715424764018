import { HashLink } from 'react-router-hash-link';
import styles from './notFound.module.scss';

import Text, {TextElement} from '../../components/atoms/text';
import Button from '../../components/atoms/button';


const NotFound = () => {

  return (
    <div className={styles.wrapper}>
      <div>
        <Text as={TextElement.Header1}>
          Erreur <span className={styles.reverse}>404.</span>
        </Text>
        <Text className={styles.subtext} as={TextElement.ClientName}>
          (la fameuse)
        </Text>
      </div>
      <HashLink smooth to="/#studies">
        <Button className={styles.studyButton}>
          Découvrez mes cas d'études
        </Button>
      </HashLink>
    </div>
  );
};


export default NotFound;
